<template>
  <div class="container">
    <div class="row ">
      <img src="./iheader.png" alt="header" width="100%" />
    </div>
    <h2 class="mt-3 text-center br p-2"><b> فاتورة ضريبية </b></h2>
    <div class="row mt-4">
      <div class="col-sm-6 col-md-6 invoice-head">
        <h3><b> اسم المورد : شركة ابناء عبدالرحمن على البصيلى </b></h3>
        <h3><b> 1010152299 : رقم السجل التجارى </b></h3>

        <h3><b>300047805100003 : الرقم الضريبى </b></h3>
        <h3><b>4173 : صندوق بريد </b></h3>
        <h3><b>11491: الرمز البريدى </b></h3>
        <h3><b> الملز , الرياض </b></h3>
        <br />
        <h3><b> الى </b></h3>
        <h3>
          <b> اســم الشـركة: شركة مرافق الكهرباء والمياء بالجبيل وينبع </b>
        </h3>

        <h3><b>30144 : صندوق بريد </b></h3>
        <h3><b> ينبع الصنا عية - الرمز البريدى :41912 </b></h3>
        <h3><b> 300000419900003 :الرقم الضريبى </b></h3>
      </div>
      <div class="col-sm-6 col-md-6 invoice-head">
        <h3>
          <b>{{ invoice.invoice_id }} : رقم الفاتورة </b>
        </h3>
        <h3>
          <b> رقم المرجع : {{ invoice.ref_invoice }} </b>
        </h3>

        <h3>
          <b
            >{{ invoice.created_at | moment("DD/MM/YYYY") }}: تاريخ الفاتورة
          </b>
        </h3>
        <h3>
          <b>
            {{ invoice.invoice_end | moment("DD/MM/YYYY") }}-
            {{ invoice.invoice_start | moment("DD/MM/YYYY") }} : مدة الفاتورة</b
          >
        </h3>
        <h3>
          <b> نــوع الفاتورة : {{ hc_address }} </b>
        </h3>
        <div class="p-0 m-0 float-right">
          <figure class="qrcode">
            <vue-qrcode
              :value="invoice.qrcode"
              :options="{ errorCorrectionLevel: 'Q', width: 200 }"
            ></vue-qrcode>
          </figure>
        </div>
      </div>
    </div>

    <div class="row border mt-2 pt-3">
      <div class="col-sm-12 col-md-12 col-xs-12 col-xxl-12">
        <h3 class="float-right">
          <b
            ><span class="pl-1">
              {{ invoice.contract_no }}: رقـــم العــقــد</span
            >
          </b>
        </h3>
      </div>
      <div class="col-sm-12">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="float-center">الأجمالي</th>
              <th class="float-center">الساعات المنجزة</th>
              <th class="float-center">سعر الساعــة</th>
              <th class="float-center">البـيـان</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) in items" :key="value.item">
              <td>
                <p class="float-right">{{ comma(value.amount) }}</p>
              </td>
              <td>
                <p class="float-center">{{ value.quantity }}</p>
              </td>
              <td>
                <p class="float-center">{{ comma(value.price) }}</p>
              </td>
              <td>
                <p class="float-right">{{ value.item }}</p>
              </td>
              <td>
                <p class="float-right">{{ index + 1 }}</p>
              </td>
            </tr>
            <tr>
              <td colspan="1">
                <span class="float-right"> {{ invoice_total_amount }} </span>
              </td>
              <td colspan="4">
                <span class="float-right">
                  الاجمــالي قبل الضريبه بالريال السعودي
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="1">
                <span class="float-right"> {{ invoice_vat_amount }} </span>
              </td>
              <td colspan="4">
                <span class="float-right">
                  نسبة ضريبة القيمة المضافة %15 بالريال السعودي
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="1">
                <span class="float-right"> {{ invoice_gross_amount }} </span>
              </td>
              <td colspan="4">
                <span class="float-right">
                  الاجمــــــالى بالريال السعودي بعد الضريبة
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-12 mt-3">
        <h4 class="word float-right">
          <b> القيمة بالحروف : {{ invoice.amount_words_ar }} </b>
        </h4>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p style="width: 200px; border-top: solid 2px"></p>
        <h3><b> مـــديــر النقد </b></h3>
        <h3><b> أ / يعقوب فضل الله </b></h3>
      </div>
    </div>

    <div class="row">
      <img src="./ifooter.png" alt="header" width="100%">
    </div>
  </div>
</template>
<script>
import HRMService from "../../../../services/hrm-service";
const VueQrcode = require("@chenfengyuan/vue-qrcode");

const converter = require("number-to-words");

export default {
  name: "SalesInvoiceMarafiq",
  components: {
    VueQrcode: VueQrcode,
  },
  data: function () {
    return {
      selected_expat: "progress",
      option_expat: [
        {
          text: "Progress",
          value: "progress",
        },
        {
          text: "Retention",
          value: "retention",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      selected_approval: "yes",
      option_approval: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      invoice: {},
      items: [],
      invoice_total_amount: 0,
      invoice_vat_amount: 0,
      invoice_gross_amount: 0,
      invoice_rentention_amount: 0,
      invoice_net_amount: 0,
      // amount_words: "",
      hc_address: "",
    };
  },
  mounted() {
    this.retrieve(this.$route.params.id);
  },
  methods: {
    comma(data) {
      //  return data
      return Number(parseFloat(data).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2,
      });
    },
    retrieve(id) {
      HRMService.getOrderItems(id)
        .then((response) => {
          this.invoice = response.data["sales"][0];
          this.items = response.data["items"];
          this.invoice_total_amount = this.comma(this.invoice.total_amount);
          this.invoice_vat_amount =  this.comma(this.invoice.vat_amount);
          this.invoice_gross_amount = this.comma(this.invoice.gross_amount);;
          this.invoice_rentention_amount = this.comma(this.invoice.retention_amount);
          this.invoice_net_amount = this.comma(this.invoice.net_amount)
          // this.amount_words = converter.toWords(this.invoice_net_amount);

          if (this.invoice.invoice_type == "Progress Service Order") {
            this.hc_address = "الدفــعـات";
          } else if (this.invoice.invoice_type == "Retention") {
            this.hc_address = "الاستــقـطاع";
          } else if (this.invoice.invoice_type == "Other") {
            this.hc_address = "اخــري";
          } else {
            this.hc_address = "";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.fs-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.center {
  width: 1100px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.border {
  border: solid 2px black !important;
}
.word {
  text-transform: capitalize;
}
.fa-bold {
  font-weight: bold;
}

.float-center {
  text-align: center;
}

.box {
  width: 20px;
  height: 25px;
  border: 1px solid black;
}
.line-height {
  line-height: 0.5px;
}

.border-top {
  border-top: solid 1px black !important;
}

.border-bottom {
  border-bottom: 3px double black !important;
}

.table th {
  font-size: 20px !important;
}

.table td {
  margin: 4px;
  font-size: 18px !important;
}
table.table-bordered > thead > tr > th {
  border: 1px solid black;
}
table.table-bordered > tbody > tr > td {
  border: 1px solid black;
}
.invoice-head h3 {
  text-align: right;
}
.qrcode {
  display: inline-block;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
}
</style>